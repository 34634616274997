import { useCallback } from 'react'
import { USER_ADMINS_ROLES, USER_ATTORNEY_ROLES, USER_ROLES } from '@/constants'
import { useUserOrganizacionQuery } from '@/gql/systemApi'

const useGetMyRoleAndOrg = () => {
  const { data: userOrgData, loading: userOrgDataLoading } =
    useUserOrganizacionQuery()

  const userId = userOrgData?.users_me?.id
  const roleId = userOrgData?.users_me?.role?.id
  const orgId =
    userOrgData?.users_me?.current_organization?.[0]?.organization_id?.id

  const isVetted = userOrgData?.users_me?.attorneys?.[0]?.is_vetted

  const isAttorney = USER_ATTORNEY_ROLES.includes(roleId || '')
  const isAdmin = USER_ADMINS_ROLES.includes(roleId || '')
  const isCaseManager = roleId === USER_ROLES.CaseManagerUser
  const isAdminAttorney = isAdmin && isAttorney

  const getIsSameOrg = useCallback(
    (orgIdToCompare: string) => orgId === orgIdToCompare,
    [orgId]
  )

  return {
    userOrgData,
    loading: userOrgDataLoading,
    isVetted,
    isAdmin,
    isAttorney,
    isAdminAttorney,
    isCaseManager,
    getIsSameOrg,
    orgId,
    userId,
  }
}

export default useGetMyRoleAndOrg
