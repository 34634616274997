import { refreshToken } from '@/apollo'
import {
  sessionStorageService,
  SessionKey,
} from '@/services/SessionStorage/SessionStorageService'
import axios, { CreateAxiosDefaults } from 'axios'

export function createAxiosInstance(config: CreateAxiosDefaults = {}) {
  const instance = axios.create({
    transformRequest: [
      function authorizeRequest(data) {
        const token = sessionStorageService.getItem(SessionKey.TOKEN)
        if (token) {
          this.headers.Authorization = `Bearer ${token}`
        }
        return data
      },
      ...(Array.isArray(axios.defaults.transformRequest)
        ? axios.defaults.transformRequest
        : [axios.defaults.transformRequest ?? (data => data)]),
    ],
    ...config,
  })

  instance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config
      if (
        (error.response?.status === 401 || error.response?.status === 403) &&
        // eslint-disable-next-line no-underscore-dangle
        !originalRequest._retry
      ) {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true
        try {
          await refreshToken()
          return await instance(originalRequest)
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError)
          return Promise.reject(refreshError)
        }
      }
      return Promise.reject(error)
    }
  )

  return instance
}
