import { useEffect, useState, useCallback, useRef } from 'react'
import { useStreamChat } from '@/hooks/useStreamChat'
import { ChannelOptions, useChatClient } from '@/context/ChatClientContext'
import Chat from '@mui/icons-material/Chat'
import Close from '@mui/icons-material/Close'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import { Channel, DefaultGenerics } from 'stream-chat'
import ChannelListItem from '../ChannelListItem'
import CaseChannelList from './CaseChannelList'
import { StyledBadge } from '../StyledBadge'

const scrollDistanceFromChannelListBottomToTriggerFetch = 100

const ChannelList = () => {
  const theme = useTheme()
  const {
    selectedTab,
    setSelectedTab,
    getChannelListItemData,
    messageDrawerOpen,
    getMyCaseChannels,
    getMyDirectChannels,
    closeMessageDrawer,
  } = useStreamChat()

  const { unreadCaseMessages, unreadDirectMessages } = useChatClient()

  const [channels, setChannels] = useState<Channel<DefaultGenerics>[]>([])
  const [channelsLoading, setChannelsLoading] = useState<boolean>(false)

  const selectedTabLabel = () => {
    switch (selectedTab) {
      case ChannelOptions.DirectMessages:
        return 'Direct Messages'
      case ChannelOptions.CaseMessages:
        return 'Case Messages'
      default:
        return 'Messages'
    }
  }

  const observer = useRef<IntersectionObserver | null>(null)

  const fetchChannels = useCallback(async () => {
    setChannelsLoading(true)

    try {
      let newChannels: Channel<DefaultGenerics>[] = []
      switch (selectedTab) {
        case ChannelOptions.DirectMessages:
          newChannels = await getMyDirectChannels()
          break
        case ChannelOptions.CaseMessages:
          newChannels = await getMyCaseChannels()
          break
        default:
          throw new Error('No selected tab')
      }
      setChannels(newChannels)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch more channels', error)
    } finally {
      setChannelsLoading(false)
    }
  }, [getMyCaseChannels, getMyDirectChannels, selectedTab])

  const lastChannelElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (channelsLoading) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            fetchChannels()
          }
        },
        {
          rootMargin: `0px 0px ${scrollDistanceFromChannelListBottomToTriggerFetch}px 0px`,
        }
      )

      if (node) observer.current.observe(node)
    },
    [channelsLoading, fetchChannels]
  )

  useEffect(() => {
    if (!messageDrawerOpen) return

    fetchChannels()
  }, [fetchChannels, messageDrawerOpen])

  const channelList =
    selectedTab === ChannelOptions.CaseMessages ? (
      <CaseChannelList
        channels={channels}
        loading={channelsLoading}
        getChannelListItemData={getChannelListItemData}
        lastChannelElementRef={lastChannelElementRef}
      />
    ) : (
      channels.map((channel, idx) => {
        const channelItemData = getChannelListItemData(channel)

        if (!channelItemData) return null

        return (
          <ChannelListItem
            key={channel.id}
            ref={
              idx === channels.length - 1 ? lastChannelElementRef : undefined
            }
            {...channelItemData}
          />
        )
      })
    )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="h6" noWrap>
          {selectedTabLabel()}
        </Typography>
        <IconButton onClick={closeMessageDrawer}>
          <Close />
        </IconButton>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {channelList}
      </Box>

      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          position: 'static',
          flexShrink: 0,
        }}
      >
        <Tab
          label="Case Messages"
          value={ChannelOptions.CaseMessages}
          icon={
            <StyledBadge
              badgeContent={unreadCaseMessages}
              color="error"
              sx={{
                '.MuiBadge-badge': {
                  borderRadius: '100%',
                },
              }}
            >
              <BusinessCenterOutlinedIcon />
            </StyledBadge>
          }
        />
        <Tab
          label="Direct Messages"
          value={ChannelOptions.DirectMessages}
          icon={
            <StyledBadge
              badgeContent={unreadDirectMessages}
              color="error"
              sx={{
                '.MuiBadge-badge': {
                  borderRadius: '100%',
                },
              }}
            >
              <Chat />
            </StyledBadge>
          }
        />
      </Tabs>
    </>
  )
}

export default ChannelList
