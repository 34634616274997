import request from '@/axios/config'
import { DATAROOM_FOLDERS } from '@/constants'
import { AxiosRequestConfig } from 'axios'

export const useFileUpload = (isDataroom?: boolean) => {
  const upload = (
    file: File,
    onUploadProgress: AxiosRequestConfig<FormData>['onUploadProgress'],
    caseId?: string,
    casePurchaseId?: string
  ): Promise<unknown> => {
    const formData = new FormData()

    if (caseId) {
      // new dataroom
      formData.append('tags', caseId)
    } else {
      // old dataroom
      formData.append(
        'folder',
        isDataroom
          ? DATAROOM_FOLDERS.DataRoomFolderID
          : DATAROOM_FOLDERS.PublicFolderID
      )
    }

    if (casePurchaseId) {
      formData.append(
        'metadata',
        JSON.stringify({
          is_fee_agreement: true,
          case_purchase_id: casePurchaseId,
        })
      )
    }

    formData.append('file', file)

    return request.post('/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  return { upload }
}
