import { InstantSearch } from 'react-instantsearch'
import { liteClient as createSearchClient } from 'algoliasearch/lite'
import { AttorneyAutocomplete } from './AttorneyAutocomplete'
import { AlgoliaSearchProps } from './types'

const {
  VITE_ALGOLIA_APP_ID,
  VITE_ALGOLIA_API_KEY,
  VITE_ALGOLIA_ATTORNEY_INDEX,
} = import.meta.env

const searchClient = createSearchClient(
  VITE_ALGOLIA_APP_ID,
  VITE_ALGOLIA_API_KEY
)

export const AlgoliaAttorneySearch = ({
  placeholder = 'Search attorneys...',
  label,
  value,
  onChange,
  filters,
  customStyles,
  noOptionsText,
  startAdornment,
  color,
  multiple,
  renderTags,
  getOptionDisabled,
}: AlgoliaSearchProps) => (
  <InstantSearch
    searchClient={searchClient}
    indexName={VITE_ALGOLIA_ATTORNEY_INDEX}
  >
    <AttorneyAutocomplete
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      customStyles={customStyles}
      filters={filters}
      noOptionsText={noOptionsText}
      startAdornment={startAdornment}
      color={color}
      multiple={multiple}
      renderTags={renderTags}
      getOptionDisabled={getOptionDisabled}
    />
  </InstantSearch>
)
