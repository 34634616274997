import { useQuery } from '@apollo/client'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useUpdatePermissions from '@/hooks/permissions/useUpdatePermissions'
import { FeatureFlag } from '@/optimizely/types'

import { useFeatureFlags } from '@/optimizely/useFeatureFlags'
import { IS_LOGGED_IN } from './queries'

interface AuthRouteProps {
  children: ReactNode
}

export interface SignInQueryResult {
  isLoggedIn: boolean
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const location = useLocation()
  const { data } = useQuery<SignInQueryResult>(IS_LOGGED_IN)
  useUpdatePermissions({ isLoggedIn: Boolean(data?.isLoggedIn) })
  const featureFlags = useFeatureFlags()
  const isDashboardV2Enabled =
    featureFlags[FeatureFlag.MARKETPLACE_DASHBOARD_V2]

  const redirectUrl = isDashboardV2Enabled ? '/myReferrals' : '/marketplace'

  if (data?.isLoggedIn && location.pathname === '/signIn') {
    return <Navigate state={{ from: location }} to={redirectUrl} />
  }

  if (!data?.isLoggedIn && !(location.pathname === '/signIn')) {
    return <Navigate state={{ from: location }} to="/signIn" />
  }

  return children
}

export default AuthRoute
