import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const MessageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.404 22.0022 8.83083 21.6208 7.41301 20.888L3.58701 21.955C3.37355 22.0146 3.14809 22.0163 2.93373 21.9601C2.71937 21.9038 2.52382 21.7916 2.36712 21.6349C2.21041 21.4782 2.09818 21.2826 2.04194 21.0683C1.98569 20.8539 1.98744 20.6285 2.04701 20.415L3.11501 16.592C2.38036 15.1729 1.99793 13.5979 2.00001 12C2.00001 6.477 6.47701 2 12 2ZM12 3.5C9.74567 3.5 7.58366 4.39553 5.9896 5.98959C4.39554 7.58365 3.50001 9.74566 3.50001 12C3.50001 13.47 3.87301 14.883 4.57301 16.137L4.72301 16.407L3.61101 20.391L7.59801 19.279L7.86801 19.429C9.00239 20.0597 10.2661 20.4222 11.5624 20.4888C12.8586 20.5554 14.1528 20.3242 15.3458 19.8131C16.5389 19.3019 17.599 18.5244 18.445 17.54C19.2909 16.5556 19.9002 15.3906 20.2261 14.1343C20.552 12.8779 20.5858 11.5636 20.325 10.2922C20.0643 9.02073 19.5158 7.82589 18.7216 6.79927C17.9275 5.77264 16.9088 4.94152 15.7437 4.36962C14.5786 3.79773 13.2979 3.50025 12 3.5ZM8.75001 13H13.248C13.438 13.0001 13.6209 13.0722 13.7598 13.202C13.8987 13.3317 13.9831 13.5093 13.996 13.6989C14.009 13.8885 13.9495 14.0759 13.8296 14.2233C13.7097 14.3707 13.5383 14.4671 13.35 14.493L13.248 14.5H8.75001C8.55999 14.4999 8.37707 14.4278 8.23822 14.298C8.09937 14.1683 8.01493 13.9907 8.00198 13.8011C7.98903 13.6115 8.04852 13.4241 8.16844 13.2767C8.28836 13.1293 8.45976 13.0329 8.64801 13.007L8.75001 13ZM8.75001 9.5H15.255C15.4449 9.50031 15.6277 9.57267 15.7664 9.70248C15.905 9.83229 15.9893 10.0099 16.0021 10.1994C16.0149 10.3889 15.9553 10.5762 15.8354 10.7235C15.7155 10.8708 15.5442 10.9671 15.356 10.993L15.255 11H8.75001C8.55999 10.9999 8.37707 10.9278 8.23822 10.798C8.09937 10.6683 8.01493 10.4907 8.00198 10.3011C7.98903 10.1115 8.04852 9.92411 8.16844 9.7767C8.28836 9.62929 8.45976 9.5329 8.64801 9.507L8.75001 9.5Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  </SvgIcon>
)

export default MessageIcon
