import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'

export type AvatarProps = {
  src: string
  alt: string
}

type AvatarDisplayProps = {
  images: AvatarProps[]
}

const nameToInitials = (name: string) => {
  const [firstName, lastName] = name.split(' ')

  return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase()
}

// TODO: Pass user names to the alt attribute for fallback initial display
export const AvatarDisplay: React.FC<AvatarDisplayProps> = ({ images }) => {
  const theme = useTheme()
  if (!images || images.length === 0) {
    return <Avatar alt="Avatar" />
  }
  if (images.length === 1) {
    return (
      <Avatar alt={images[0].alt || 'Avatar'} src={images[0].src}>
        <Typography
          fontSize={theme.spacing(2.5)}
          fontFamily="Helvetica"
          position="relative"
          top={theme.spacing(0.25)}
        >
          {nameToInitials(images[0].alt)}
        </Typography>
      </Avatar>
    )
  }
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Avatar
        alt={images[0].alt || 'Avatar 1'}
        src={images[0].src}
        sx={{
          width: 36,
          height: 36,
          boxSizing: 'content-box',
          border: `3px solid ${theme.palette.common.white}`,
          zIndex: 2,
          bottom: -4,
        }}
      >
        <Typography
          fontSize={theme.spacing(2.5)}
          fontFamily="Helvetica"
          position="relative"
          top={theme.spacing(0.25)}
        >
          {nameToInitials(images[0].alt)}
        </Typography>
      </Avatar>
      <Avatar
        alt={images[1].alt || 'Avatar 2'}
        src={images[1].src}
        sx={{
          width: 26,
          height: 26,
          zIndex: 1,
          position: 'relative',
          right: 12,
          top: -10,
        }}
      >
        <Typography
          fontSize={theme.spacing(1.75)}
          fontFamily="Helvetica"
          position="relative"
          top={theme.spacing(0.125)}
        >
          {nameToInitials(images[1].alt)}
        </Typography>
      </Avatar>
    </Box>
  )
}

export default AvatarDisplay
