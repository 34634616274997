import { createBrowserRouter, Navigate } from 'react-router-dom'
import { ReactNode, Suspense } from 'react'
import { lazyWithPreload } from 'react-lazy-with-preload'

import AuthRoute from '@/apollo/AuthRoute'
import { DocumentTitle } from '@/components'
import { IS_MAINTENANCE_ENABLED } from '@/constants'
import Error from '@/pages/Error/Error'
import CustomLoading from '@/components/Loading'
import { RoutesData } from './RoutesData'

// Lazy load all components
const CreateAccount = lazyWithPreload(
  () => import('@/pages/CreateAccount/CreateAccount')
)
CreateAccount.preload()

const EditPublicProfile = lazyWithPreload(
  () => import('@/pages/Profile/EditPublicProfile')
)
EditPublicProfile.preload()

const Marketplace = lazyWithPreload(() => import('@/pages/Marketplace'))
Marketplace.preload()

const PublicProfile = lazyWithPreload(
  () => import('@/pages/Profile/PublicProfile')
)
PublicProfile.preload()

const MyNetwork = lazyWithPreload(() => import('@/pages/MyNetwork'))
MyNetwork.preload()

const MyReferrals = lazyWithPreload(
  () => import('@/pages/MyReferralsV3/MyReferrals')
)
MyReferrals.preload()

const CreateNewCase = lazyWithPreload(() => import('@/pages/CreateNewCase'))
CreateNewCase.preload()

const EditCase = lazyWithPreload(() => import('@/pages/EditCase'))
EditCase.preload()

const CreateProfile = lazyWithPreload(
  () => import('@/pages/CreateProfile/CreateProfile')
)
CreateProfile.preload()

const SignIn = lazyWithPreload(() => import('@/pages/SignIn/SignIn'))
SignIn.preload()

const SignOut = lazyWithPreload(() => import('@/pages/SignOut/SignOut'))
SignOut.preload()

const AccountSettings = lazyWithPreload(() => import('@/pages/AccountSettings'))
AccountSettings.preload()

const Case = lazyWithPreload(() => import('@/pages/Case'))
Case.preload()

const AdminPanel = lazyWithPreload(() => import('@/pages/AdminPanel'))
AdminPanel.preload()

const Verification = lazyWithPreload(
  () => import('@/pages/Verification/Verification')
)
Verification.preload()

const ForgotPassword = lazyWithPreload(
  () => import('@/pages/ForgotPassword/ForgotPassword')
)
ForgotPassword.preload()

const Maintenance = lazyWithPreload(
  () => import('@/pages/Maintenance/Maintenance')
)
Maintenance.preload()

const DashboardRouter = lazyWithPreload(() => import('./DashboardRouter'))
DashboardRouter.preload()

const Analytics = lazyWithPreload(() => import('@/pages/Analytics/Analytics'))
Analytics.preload()

const isMaintenanceEnabled = IS_MAINTENANCE_ENABLED === 'true'

const SuspenseWrapper = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<CustomLoading />}>{children}</Suspense>
)

export const Routes: Partial<
  Record<
    keyof typeof RoutesData,
    { element: ReactNode; errorElement: ReactNode }
  >
> = {
  RHome: {
    element: <Navigate to="/signIn" replace />,
    errorElement: <Error />,
  },
  RCreateAccount: {
    element: (
      <SuspenseWrapper>
        <DocumentTitle title={RoutesData.RCreateAccount.title} />
        <CreateAccount />
      </SuspenseWrapper>
    ),
    errorElement: <Error />,
  },
  RClaimInvite: {
    element: (
      <SuspenseWrapper>
        <DocumentTitle title={RoutesData.RClaimInvite.title} />
        <CreateAccount />
      </SuspenseWrapper>
    ),
    errorElement: <Error />,
  },
  REditProfile: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.REditProfile.title} />
          <EditPublicProfile />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RProfile: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RProfile.title} />
          <PublicProfile />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },

  RMyNetwork: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RMyNetwork.title} />
          <MyNetwork />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RMarketplace: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RMarketplace.title} />
          <Marketplace />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RMyReferrals: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RMyReferrals.title} />
          <MyReferrals />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RCreateProfile: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RCreateProfile.title} />
          <CreateProfile />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RCreateNewCase: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RCreateNewCase.title} />
          <CreateNewCase />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  REditCase: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.REditCase.title} />
          <EditCase />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RSignIn: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RSignIn.title} />
          <SignIn />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RSignOut: {
    element: <SignOut />,
    errorElement: <Error />,
  },
  RSettings: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RSettings.title} />
          <AccountSettings />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RCase: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RCase.title} />
          <Case />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RAdmin: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RAdmin.title} />
          <AdminPanel />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RVerification: {
    element: (
      <SuspenseWrapper>
        <DocumentTitle title={RoutesData.RVerification.title} />
        <Verification />
      </SuspenseWrapper>
    ),
    errorElement: <Error />,
  },
  RResetPassword: {
    element: (
      <SuspenseWrapper>
        <DocumentTitle title={RoutesData.RResetPassword.title} />
        <ForgotPassword />
      </SuspenseWrapper>
    ),
    errorElement: <Error />,
  },
  RDashboard: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RDashboard.title} />
          <DashboardRouter />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
  RAnalytics: {
    element: (
      <AuthRoute>
        <SuspenseWrapper>
          <DocumentTitle title={RoutesData.RAnalytics.title} />
          <Analytics />
        </SuspenseWrapper>
      </AuthRoute>
    ),
    errorElement: <Error />,
  },
}

const routesArr: {
  element: ReactNode
  errorElement: ReactNode
  path: string
}[] = Object.keys(Routes).map(key => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ...Routes[key],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ...RoutesData[key],
}))

const rootRouter = createBrowserRouter(
  routesArr.map(r => ({
    element: isMaintenanceEnabled ? <Maintenance /> : r.element,
    errorElement: r.errorElement,
    path: r.path,
  }))
)

export default rootRouter
