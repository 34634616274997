import { DrawerProps } from '@mui/material/Drawer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FC, ReactNode } from 'react'

import { theme } from '@/styles/theme'

import * as Styles from './SideDrawer.styles'

const sm = theme.breakpoints.up('sm')

interface SideDrawerProps {
  children: ReactNode
  onClose: () => void
  open: boolean
}

const SideDrawer: FC<SideDrawerProps> = ({ children, onClose, open }) => {
  const isLargeScreen = useMediaQuery(sm)

  const anchor: DrawerProps['anchor'] = !isLargeScreen ? 'bottom' : 'right'

  return (
    <Styles.Drawer anchor={anchor} open={open} onClose={onClose}>
      <Styles.DrawerContent>{children}</Styles.DrawerContent>
    </Styles.Drawer>
  )
}

export default SideDrawer
