import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { IMAGE_SIZES } from '@/constants'
import { getImagePath } from '@/utils'
import { isQualifiedMembership, isVettedMembership } from '@/utils/membership'
import JDropdownUser from '../atoms/JDropdownUser'
import { useAlgoliaAttorneySearch } from './useAlgoliaAttorneySearch'
import { AlgoliaSearchProps } from './types'

export const AttorneyAutocomplete = ({
  value,
  onChange,
  label,
  placeholder,
  customStyles,
  filters,
  maxSelections,
  noOptionsText = 'No attorneys found',
  multiple,
  renderTags,
  getOptionDisabled,
  startAdornment,
  color,
}: AlgoliaSearchProps) => {
  const { attorneys, searchAttorneys, loading } = useAlgoliaAttorneySearch({
    filters,
    maxSelections,
  })

  return (
    <Autocomplete
      multiple={multiple}
      options={attorneys || []}
      loading={loading}
      value={value}
      onChange={(_, newValue) => {
        onChange(newValue)
      }}
      getOptionLabel={attorney => {
        if (Array.isArray(attorney)) {
          return ''
        }
        return `${attorney.first_name} ${attorney.last_name}`
      }}
      onInputChange={(_, newInputValue) => {
        searchAttorneys(newInputValue)
      }}
      noOptionsText={noOptionsText}
      renderTags={renderTags}
      getOptionDisabled={getOptionDisabled}
      // @ts-expect-error For some reason key is actually defined and needs to
      // be picked out to avoid being spread to the DOM node
      renderOption={({ key, ...props }, option) => (
        <JDropdownUser
          key={option.id}
          user={{
            id: option.id,
            name: `${option.first_name} ${option.last_name}`,
            picture: getImagePath({
              id: option?.avatar_url?.split('/').at(-1) || null,
              size: IMAGE_SIZES.Small,
            }),
            organization: option?.organization?.name || '',
            isVetted: isVettedMembership(option?.membership_type.id),
            isQualified: isQualifiedMembership(option?.membership_type.id),
          }}
          {...props}
        />
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          color={color}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment ? (
              <>
                {startAdornment}
                {params.InputProps.startAdornment}
              </>
            ) : (
              params.InputProps.startAdornment
            ),
          }}
        />
      )}
      sx={customStyles}
    />
  )
}
