import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'

import Search, { SearchProps } from '../Search'

export interface ContentLayoutProps {
  title: string
  subtitle: string
  SearchProps?: SearchProps
  children?: React.ReactNode
}

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'none',
  },
}))

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
  },
}))

const GridContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 2),
  },
}))

const ContentLayout = ({
  title,
  subtitle,
  SearchProps: searchProps,
  children,
}: ContentLayoutProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '100%',
        padding: { md: theme.spacing(5), xs: theme.spacing(0) },
        paddingBottom: { md: theme.spacing(0), xs: theme.spacing(8) },
        overflow: 'auto',
        width: '100%',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
      className="Content-Layout"
    >
      <Grid container spacing={2}>
        <GridContainer item md={6} xs={12}>
          <Title variant="h3">{title}</Title>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1.5 }}>
            {subtitle}
          </Typography>
        </GridContainer>
        <GridContainer item md={6} xs={12}>
          <SearchContainer>
            <Search {...searchProps} />
          </SearchContainer>
        </GridContainer>
        <Grid item mt={2} md={12} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}
export default ContentLayout
