import { makeUseAxios } from 'axios-hooks'
import { createAxiosInstance } from './createAxiosInstance'

const API_URL = import.meta.env.VITE_API_URL
export const API_URL_ROOT = API_URL.replace('/graphql', '')

const request = createAxiosInstance({ baseURL: API_URL_ROOT })

export const useAxios = makeUseAxios({
  axios: request,
  defaultOptions: { manual: true },
})

export default request
