import { PATHS_WITH_SECTIONS } from './RoutesUtils'

export const RoutesData = {
  RHome: {
    path: '/',
    title: 'Home',
  },
  RCreateAccount: {
    path: '/createAccount',
    title: 'Create Account',
  },
  RDashboard: {
    path: '/dashboard',
    title: 'Dashboard',
  },
  RAnalytics: {
    path: '/analytics',
    title: 'Analytics',
  },
  REditProfile: {
    path: `${PATHS_WITH_SECTIONS.EDIT_PUBLIC_PROFILE}/:id`,
    title: 'Edit Public Profile',
    mobileBackNavbar: true,
  },

  RProfile: {
    path: '/profile/:id',
    title: 'Attorney profile',
    mobileBackNavbar: true,
  },
  RMyNetwork: {
    path: `${PATHS_WITH_SECTIONS.MY_NETWORK}/:id`,
    title: 'My Network',
  },
  RMarketplace: {
    path: '/marketplace',
    title: 'Marketplace',
  },
  RMyReferrals: {
    path: '/myReferrals',
    title: 'My Referrals',
  },
  RCreateProfile: {
    path: '/createProfile',
    title: 'Create Profile',
  },
  RCreateNewCase: {
    path: '/createNewCase',
    title: 'Create New Case',
    mobileBackNavbar: true,
  },
  REditCase: {
    path: `${PATHS_WITH_SECTIONS.CASE_WITH_ID}/:id/edit`,
    title: 'Edit Case',
    mobileBackNavbar: true,
  },
  RSignIn: {
    path: '/signIn',
    title: 'Sign In',
  },
  RSignOut: {
    path: '/signOut',
    title: 'Sign Out',
  },
  RSettings: {
    path: `${PATHS_WITH_SECTIONS.ACCOUNT_SETTINGS}/:id`,
    title: 'Account & Settings',
    mobileBackNavbar: true,
  },
  RCase: {
    path: `${PATHS_WITH_SECTIONS.CASE_WITH_ID}/:id`,
    title: 'Case',
    mobileBackNavbar: true,
  },
  RAdmin: {
    path: `${PATHS_WITH_SECTIONS.ADMIN_PANEL}/:id`,
    title: 'Admin Panel',
  },
  RVerification: {
    path: '/validateAccount',
    title: 'Validate Account',
  },
  RClaimInvite: {
    path: '/claimInvite',
    title: 'Create Account',
  },
  RResetPassword: {
    path: '/resetPassword',
    title: 'Reset Password',
  },
}

const PathToKeyModel: Record<string, keyof typeof RoutesData> = {
  '/': 'RHome',
  '/createAccount': 'RCreateAccount',
  '/dashboard': 'RDashboard',
  '/analytics': 'RAnalytics',
  '/claimInvite': 'RCreateAccount',
  '/editPublicProfile': 'REditProfile',
  '/profile': 'RProfile',
  '/myNetwork': 'RMyNetwork',
  '/marketplace': 'RMarketplace',
  '/myReferrals': 'RMyReferrals',
  '/createProfile': 'RCreateProfile',
  '/createNewCase': 'RCreateNewCase',
  '/editCase': 'REditCase',
  '/signIn': 'RSignIn',
  '/signOut': 'RSignOut',
  '/accountsettings': 'RSettings',
  '/case': 'RCase',
  '/admin': 'RAdmin',
  '/validateAccount': 'RVerification',
  '/resetPassword': 'RResetPassword',
}

export const getPageData = (route: string) => RoutesData[PathToKeyModel[route]]
