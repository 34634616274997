import './index.css'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'

import { theme } from '@/styles/theme'

import Bootstrap from '@/components/Bootstrap'
import App from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Bootstrap>
      <App />
    </Bootstrap>
  </ThemeProvider>
)
