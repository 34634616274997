import { useSearchBox, useHits, useConfigure } from 'react-instantsearch'
import { useCallback } from 'react'
import { useDebounceCallback } from 'usehooks-ts'
import {
  AlgoliaAttorney,
  AlgoliaSearchFilters,
  AlgoliaSearchProps,
} from './types'

const buildAlgoliaFilters = (
  filters?: AlgoliaSearchProps['filters']
): string => {
  const filterParts: string[] = []
  const { exclude, ...include } = filters ?? {}

  const processFilters = (
    filterToApply: Omit<AlgoliaSearchFilters, 'exclude'>,
    isExclude = false
  ) => {
    Object.entries(filterToApply).forEach(([key, values]) => {
      const prefix = isExclude ? 'NOT ' : ''

      if (Array.isArray(values) && values.length > 0) {
        const valueFilters = values
          .map((value: string) => `${prefix}${key}:"${value}"`)
          .join(' OR ')
        filterParts.push(`(${valueFilters})`)
      } else if (typeof values === 'string') {
        filterParts.push(`${prefix}${key}:"${values}"`)
      } else if (typeof values === 'boolean') {
        filterParts.push(`${prefix}${key}:${values}`)
      }
    })
  }

  if (include) {
    processFilters(include)
  }

  if (exclude) {
    processFilters(exclude, true)
  }

  return filterParts.join(' AND ')
}

export const useAlgoliaAttorneySearch = ({
  filters,
  maxSelections = 50,
  page = 0,
}: Pick<AlgoliaSearchProps, 'filters' | 'maxSelections' | 'page'>) => {
  const { refine } = useSearchBox()
  const { results } = useHits<AlgoliaAttorney>()

  const filterString = buildAlgoliaFilters(filters)

  useConfigure({
    filters: filterString,
    hitsPerPage: maxSelections,
    page,
  })

  const handleSearch = useCallback(
    (query: string) => {
      refine(query)
    },
    [refine]
  )

  const searchAttorneys = useDebounceCallback(handleSearch, 250)

  return {
    attorneys: results?.hits,
    attorneysCount: results?.nbHits ?? 0,
    loading: !results,
    searchAttorneys,
  }
}
